import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import 'bootstrap/scss/bootstrap.scss';
import './styles/App.scss';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/nova-dark/theme.css';
import 'primeicons/primeicons.css';
/*
import 'bootstrap/scss/_functions.scss';
import 'bootstrap/scss/_variables.scss';
import 'bootstrap/scss/_mixins.scss';
import 'bootstrap/scss/_reboot.scss';
import 'bootstrap/scss/_type.scss';
import 'bootstrap/scss/_images.scss';
import 'bootstrap/scss/_code.scss';
import 'bootstrap/scss/_grid.scss';
 */
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
