import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import ReviewsMenuBar from "./ReviewsMenuBar";

// This site has 3 pages, all of which are rendered
// dynamically in the browser (not server rendered).
//
// Although the page does not ever refresh, notice how
// React Router keeps the URL up to date as you navigate
// through the site. This preserves the browser history,
// making sure things like the back button and bookmarks
// work properly.

let BasicExample = ()=>{
    return (
        <Router>
            <div>
                {/*
                <ul>
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>
                        <Link to="/login">Login</Link>
                    </li>
                    <li>
                        <Link to="/about">About</Link>
                    </li>
                    <li>
                        <Link to="/dashboard">Dashboard</Link>
                    </li>
                </ul>
                */
                }
                <ReviewsMenuBar/>

                <hr />

                {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route path="/about">
                        <About />
                    </Route>
                    <Route path="/dashboard">
                        <Dashboard />
                    </Route>
                </Switch>
            </div>
        </Router>
    );
};
export default BasicExample;

// You can think of these components as "pages"
// in your app.

let Home = ()=>{
    return (
        <div>
            <h2>Home</h2>
        </div>
    );
};

let About = ()=>{
    return (
        <div>
            <h2>About</h2>
        </div>
    );
};

let Dashboard = ()=>{
    return (
        <div>
            <h2>Dashboard</h2>
        </div>
    );
}
