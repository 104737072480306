import React, {RefObject} from 'react';
import {Menubar} from "primereact/menubar";
import {MenuItem} from "primereact/components/menuitem/MenuItem";
import {InputText} from "primereact/inputtext";
import {Button} from 'primereact/button';
import LoginDialog, {LoginListener, TokenResponse} from "./LoginDialog";

interface LoginState {
    loggedIn:boolean;
    name?:string;
}

interface MenuBarProps {

}

interface MenuBarState {
    loginState: LoginState;
    items: MenuItem[];
}

class ReviewsMenuBar extends React.Component<MenuBarProps, MenuBarState> implements LoginListener {
    loginDialog?: RefObject<LoginDialog>;

    constructor(props:MenuBarProps) {
        super(props);
        this.loginDialog = React.createRef<LoginDialog>();
        this.showLoginDialog = this.showLoginDialog.bind(this);
        this.state = {
            loginState: {
                loggedIn:false
            },
            items: [
                {
                    label: 'Users',
                    disabled: true,
                    icon: 'pi pi-fw pi-user',
                    items: [
                        {
                            label: 'New',
                            icon: 'pi pi-fw pi-user-plus',

                        },
                        {
                            label: 'Delete',
                            icon: 'pi pi-fw pi-user-minus',

                        },
                        {
                            label: 'Search',
                            icon: 'pi pi-fw pi-users',
                            items: [
                                {
                                    label: 'Filter',
                                    icon: 'pi pi-fw pi-filter',
                                    items: [
                                        {
                                            label: 'Print',
                                            icon: 'pi pi-fw pi-print'
                                        }
                                    ]
                                },
                                {
                                    icon: 'pi pi-fw pi-bars',
                                    label: 'List'
                                }
                            ]
                        }
                    ]
                },
                {
                    label: 'Events',
                    disabled: true,
                    icon: 'pi pi-fw pi-calendar',
                    items: [
                        {
                            label: 'Edit',
                            icon: 'pi pi-fw pi-pencil',
                            items: [
                                {
                                    label: 'Save',
                                    icon: 'pi pi-fw pi-calendar-plus'
                                },
                                {
                                    label: 'Delete',
                                    icon: 'pi pi-fw pi-calendar-minus'
                                },

                            ]
                        },
                        {
                            label: 'Archive',
                            icon: 'pi pi-fw pi-calendar-times',
                            items: [
                                {
                                    label: 'Remove',
                                    icon: 'pi pi-fw pi-calendar-minus'
                                }
                            ]
                        }
                    ]
                },
            ]
        };
    }

    showLoginDialog() {
        this.loginDialog?.current?.onShow();
    }
    successfulLogin(response: TokenResponse) {
        if(response.token && response.name) {
            localStorage.setItem("jwt_token", response.token);
            this.setState({loginState:{loggedIn:true, name:response.name}});
        }
        this.loginDialog?.current?.onHide();
    }
    unsuccessfulLogin(error?:string) {
        console.log("Unsuccessful login");
    }
    componentDidMount() {
        let token = localStorage.getItem("jwt_token");
        if(token) {
            this.loginDialog?.current?.makeAuthAttempt({token:token});
        }
    }

    render() {

        return (
            <div>
                <LoginDialog ref={this.loginDialog} loginListener={this}/>
                <div className="content-section introduction">
                    <div className="feature-intro">
                        <h1>Reviews {this.state.loginState.loggedIn ? ("Welcome "+this.state.loginState.name) : ""}</h1>
                    </div>
                </div>
                <div className="content-section implementation">
                    <Menubar model={this.state.items}>
                        <InputText placeholder="Search" type="text"/>
                        <Button onClick={this.showLoginDialog} label={this.state.loginState.loggedIn ? "Logout" : "Login"} icon="pi pi-power-off" style={{marginLeft:4}}/>
                    </Menubar>
                </div>
            </div>
            /*
            <header className="App-header">

                <img src={logo} className="App-logo" alt="logo" />
                <p>
                    Edit <code>src/App.tsx</code> and save to reload.
                </p>
                <a
                    className="App-link"
                    href="https://reactjs.org"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Learn React
                </a>
            </header>
             */
        );
    }


}

export default ReviewsMenuBar;
