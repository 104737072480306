import React from 'react';
import {InputText} from "primereact/inputtext";
import {Password} from "primereact/password";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import axios, {AxiosResponse} from "axios";

interface LoginDialogState {
    visible: boolean;
    username: string;
    password: string;
}

interface LoginDialogProps {
    loginListener?:LoginListener;
}

export interface SigningPayload {
    role:string;
    username:string;
    name:string;
}
export interface TokenResponse extends SigningPayload {
    token?:string;
}

export interface AuthResponse {
    success:boolean;
    message?:string;
    tokenResponse?:TokenResponse;
}

const AUTH_URL = 'https://fvqht0ivic.execute-api.us-east-1.amazonaws.com/dev/auth_hook';


interface UserPassSignInRequest {
    username:string;
    password:string;
}

interface TokenSignInRequest {
    token:string;
}

export type SignInRequest = UserPassSignInRequest | TokenSignInRequest;

export interface LoginListener {
    successfulLogin(response:TokenResponse):void;
    unsuccessfulLogin(error?:string):void;
}

export default class LoginDialog extends React.Component<LoginDialogProps, LoginDialogState>{
    footer?: any;
    iconsTemplate?: any;
    loginListeners:Array<LoginListener> = [];

    constructor(props:LoginDialogProps) {
        super(props);
        this.handleUsernameChange = this.handleUsernameChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleLoginClick = this.handleLoginClick.bind(this);
        this.addLoginListener = this.addLoginListener.bind(this);
        this.makeAuthAttempt = this.makeAuthAttempt.bind(this);
        this.onHide = this.onHide.bind(this);
        this.onShow = this.onShow.bind(this);
        if(props.loginListener) {
            this.addLoginListener(props.loginListener);
        }
        this.state = {
            visible: false,
            username: '',
            password: ''
        };
    }
    onHide() {
        this.setState({visible: false});
    }
    onShow() {
        this.setState({visible: true});
    }
    handleUsernameChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({username:event.target.value});
    }
    handlePasswordChange(event:React.ChangeEvent<HTMLInputElement>) {
        this.setState({password:event.target.value});
    }
    makeAuthAttempt(request:SignInRequest) {
        const me = this;
        axios.post<AuthResponse>(AUTH_URL, request)
            .then((response:AxiosResponse<AuthResponse>)=>{
                if(response.data.success) {
                    me.loginListeners.forEach((listener)=>{
                        if(response.data.tokenResponse) {
                            listener.successfulLogin(response.data.tokenResponse);
                        } else {
                            listener.unsuccessfulLogin("No token response, but did get success message");
                        }
                    });
                } else {
                    me.loginListeners.forEach((listener)=>{
                        listener.unsuccessfulLogin(response.data.message);
                    })
                }
            }).catch((reason:any)=>{
            this.loginListeners.forEach((listener)=>{
                listener.unsuccessfulLogin(reason);
            })
        });
    }
    handleLoginClick(event:React.MouseEvent<HTMLButtonElement,MouseEvent>) {
        let authRequest:SignInRequest = {
            username:this.state.username,
            password:this.state.password
        };
        this.makeAuthAttempt(authRequest);
    }
    addLoginListener(loginListener:LoginListener) {
        this.loginListeners.push(loginListener);
    }

    render() {
        return (
            <Dialog header="Login" footer={this.footer} iconsTemplate={this.iconsTemplate} visible={this.state.visible} style={{width: '50vw'}} modal={true} onHide={this.onHide}>
                <div>
                    <div>
                        <h3 className="first">Username</h3>
                        <InputText value={this.state.username} onChange={this.handleUsernameChange} />
                    </div>
                    <div>
                        <h3 className="first">Password</h3>
                        <Password value={this.state.password} onChange={this.handlePasswordChange} />
                    </div>
                    <Button label="Login" onClick={this.handleLoginClick}/>
                </div>
            </Dialog>
        );
    }
}

